import { Order } from './../../interfaces/order-interfaces';
import * as fromOrder from '../actions/order.action';

export interface OrderState {
    orderList:      Order[] | [];
    responseLength: any | null;
    currentOrder:   Order;
}

const initialState: OrderState ={
    orderList:      null,
    responseLength: null,
    currentOrder:   null,
};

export function orderReducer (state = initialState, action: fromOrder.orderActions): OrderState {
    switch (action.type) {
        case fromOrder.SET_ORDER_LIST:
            return{
                ...state,
                orderList: [... action.orderList]
            };
        case fromOrder.UNSET_ORDER_LIST:
            return {
                ...state,
                orderList: null,
            };

        case fromOrder.SET_RESPONSE_LENGTH:
            return{
                ...state,
                responseLength: action.length
            };
        case fromOrder.UNSET_RESPONSE_LENGTH:
            return {
                ...state,
                responseLength: null,
            };

        case fromOrder.SET_CURRENT_ORDER:
            return{
                ...state,
                currentOrder: action.order
            };

        case fromOrder.UNSET_CURRENT_ORDER:
            return{
                ...state,
                currentOrder: null
            };

        case fromOrder.UNSET_ALL_ORDERS:
            return {
                orderList:      null,
                responseLength: null,
                currentOrder:   null,
            };

        default:
            return state;
    }
}

