
import * as fromMapData from '../actions/map.action';


export interface MapState {
    coordinatesChosen:  any | null,
    addressChosen:      any | null
}

const initialState: MapState = {
    coordinatesChosen:  null,
    addressChosen:      null
};

export function mapDataReducer (state=initialState, action:fromMapData.mapActions): MapState{

    switch (action.type) {
        case fromMapData.SET_MAP_COORDINATES:
            return{
                ...state,
                coordinatesChosen: action.payload,

            };
        case fromMapData.UNSET_MAP_COORDINATES:
            return {
                ...state,
                coordinatesChosen: null,
            };
        case fromMapData.SET_ADDRESS_CHOSEN:
            return{
                ...state,
                addressChosen: action.payload,

            };
        case fromMapData.UNSET_ADDRESS_CHOSEN:
            return {
                ...state,
                addressChosen: null,
            };

        default:
            return state;
    }
}

