<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        @if (showAvatar && user.avatar) {
            <img class="h-7 w-7 rounded-full" [src]="user.avatar" />
        }
        @if (!showAvatar || !user.avatar) {
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        }
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Ingresó como</span>
            <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <!-- <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Perfil</span>
    </button>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Configuración</span>
    </button>
    <mat-divider class="my-2"></mat-divider> -->
    <button mat-menu-item (click)="signOut()">
        <mat-icon
            [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
        ></mat-icon>
        <span>Cerrar sesión</span>
    </button>
</mat-menu>

