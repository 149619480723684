import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PersistenceService } from './services/persistence.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private persistenceService: PersistenceService,
    ) {}

    ngOnInit(): void {
        this.persistenceService.hidratateStore();
    }

    ngOnDestroy(): void {
    this.persistenceService.remove_sesionStorage();

    }
}
