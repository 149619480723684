/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'apps',
        title: 'Funciones principales',
        subtitle: 'Acceso a las funciones principales',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            // {
            //     id: 'apps.receta',
            //     title: 'Receta',
            //     type: 'basic',
            //     icon: 'heroicons_outline:cloud',
            //     link: '/apps/receta',
            // },
            {
                id: 'apps.order-list',
                title: 'Órdenes',
                type: 'basic',
                icon: 'heroicons_outline:document',
                link: '/apps/order-list',
            },
            {
                id: 'apps.order-history',
                title: 'Historial',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-list',
                link: '/apps/history-list',
            },
        ],
    },
    // {
    //     id: 'divider-1',
    //     type: 'divider',
    // },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'apps',
        title: 'Apps',
        tooltip: 'Apps',
        type: 'aside',
        icon: 'heroicons_outline:squares-2x2',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'apps',
        title: 'APPS',
        type: 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'apps',
        title: 'Apps',
        type: 'group',
        icon: 'heroicons_outline:squares-2x2',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
